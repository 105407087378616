import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import { Button } from "../components/Button"
import ButtonB from "../components/ButtonB"
import TextAccordion from "../components/TextAccordion/TextAccordion"
import "../styles/visibility-and-insights.scss"
import ScrollAnimation from "react-animate-on-scroll"
import ContactForm from "../components/ContactForm"
import LogoCarousel from "../components/LogoCarousel"

//Images
import Divider from "../images/Blue-line.png"
import { BiSearchAlt2 } from "react-icons/bi"
import { RiCompassDiscoverLine } from "react-icons/ri"
import { IoIosAnalytics } from "react-icons/io"
import { CgInsights } from "react-icons/cg"
import { FaCircle, FaRegCircle } from "react-icons/fa"

import MapImg from "../images/map.png"

//data for accordion

//Observability
const panels1 = [
  {
    icon: "",
    title: "Summary",
    content:
      "Today’s digital-first business models puts technologists at the coalface of their organisations’ response to operational needs. Rapid digital transformation has added incredible technical complexity throughout IT departments, increasing the amount of data created across the technology stack, from the application through to the network and security. More than ever before, the ability to connect full stack observability with real time business results is what delivers top-tier digital experiences.",
    image:
      "https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__340.jpg",
  },
  {
    title: "Value to our customers",
    content:
      "Our approach to data centre observability includes end-to-end network visibility and full stack visibility (traditionally Application Performance Monitoring) so that all business owners have common insights into applications, their components and network touchpoints for precise troubleshooting. Metsi’s solutions enable our customers to connect their infrastructure to the impact it has on their applications, and ultimately the business. Metsi’s specialists are dedicated to achieving the most vital function of full stack observability – reducing mean-time to repair (MTR).",
    image:
      "https://helpx.adobe.com/content/dam/help/en/stock/how-to/visual-reverse-image-search/jcr_content/main-pars/image/visual-reverse-image-search-v2_intro.jpg",
  },
  {
    title: "Use cases",
    content:
      "Our certified engineers implement observability solutions that identify early-stage performance issues not yet detectable on back-end servers. We engage stakeholders to understand KPIs and create role-based application performance visibility for business users. From agent installation, to configuring bespoke solutions for individual application needs, to daily scrum calls and status reports, our specialists execute full stack visibility, step by step. We install and construct executive dashboards, configure agents and monitoring extensions, migrate services, and optimise business transactions.",
    image:
      "https://cdn.jpegmini.com/user/images/slider_puffin_before_mobile.jpg",
  },
]

//Application Modernisation
const panels2 = [
  {
    icon: "",
    title: "Summary",
    content:
      "Legacy applications have long release cycles and are costly and resource-intensive to maintain, often being dependent on more outdated and inefficient infrastructure and services. Metsi’s application modernisation moves apps from monolithic to microservices architecture to power applications of any size and scale. This makes applications easier to scale and faster to develop independently, while leveraging containerisation to allow applications to perform consistently across devices and environments.",
    image:
      "https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__340.jpg",
  },
  {
    title: "Value to our customers",
    content:
      "How do you move business-critical applications to the cloud? Approaches range from a simple “lift and shift” - where an existing application is moved to a more conducive environment— to a complete re-architecture of a legacy application. Metsi follows the “Seven Rs” approach to application modernisation: Retire, Retain, Rehost, Replatform, Refactor, Reuse, and Replace; while adapting to the nuances of each. ",
    image:
      "https://helpx.adobe.com/content/dam/help/en/stock/how-to/visual-reverse-image-search/jcr_content/main-pars/image/visual-reverse-image-search-v2_intro.jpg",
  },
  {
    title: "Use cases",
    content:
      "Metsi’s engineers map functionality and application layers through the detailed assessment of all artefacts, these being load balancers and ingress configuration; application server level HA; messaging and data layers. The application structure is then inspected for obsolete functionality and a gap analysis is conducted. The resulting container stack is then modelled . All the elements within the stack are individually chosen, scoped, reviewed and placed, based on the requirements of the development business that will own and maintain the core product. The refactoring of code and the positioning of underlying APIs drives the definition of inter-pod communication services. The design of this inter-pod network is critical to the success of the migration and future of the application, as this drives development streams and deployment strategy for the application lifecycle.",
    image:
      "https://cdn.jpegmini.com/user/images/slider_puffin_before_mobile.jpg",
  },
]

//DevOps
const panels3 = [
  {
    icon: "",
    title: "Summary",
    content:
      "The Metsi approach combines scalable agile development processes with enterprise DevOps to achieve optimal agility. DevOps not only narrows the long-standing  divide  between development and operations teams that has existed for ages, but also combines structured processes and automation to achieve faster throughput, meaning higher quality at a lower cost.",
    image:
      "https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__340.jpg",
  },
  {
    title: "Value to our customers",
    content:
      "By integrating the Dev phases of “Build, Test, Develop, Plan, and Release” with the Ops phases of “Deliver, Deploy, Operate, Monitor, and Feedback”, Metsi enables customers to evolve their software development and delivery practices to support enterprise-scale programs. It’s about delivering higher quality, business-relevant applications in shorter software delivery cycles and feedback loops to improve time to market through speedier software delivery.",
    image:
      "https://helpx.adobe.com/content/dam/help/en/stock/how-to/visual-reverse-image-search/jcr_content/main-pars/image/visual-reverse-image-search-v2_intro.jpg",
  },
  {
    title: "Use cases",
    content:
      "Metsi’s DevOps capabilities range from CI/CD orchestration to automated integration and test automation; and cloud agnostic sprawl interoperability and automated pipelines to continuous verification, automation detection, and automated rollback. Metsi’s goal is improvement through a SRE Operating Model and Pipeline Governor to automate acceptance testing into production.",
    image:
      "https://cdn.jpegmini.com/user/images/slider_puffin_before_mobile.jpg",
  },
]

const VisibilityPage = () => {
  //gatsby-image query
  const data = useStaticQuery(graphql`
    query Images {
      hero: file(relativePath: { eq: "forest-road.jpg" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1800) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      woods: file(relativePath: { eq: "calming-woods.jpg" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1600) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      forest: file(relativePath: { eq: "aerial-forest.jpg" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1600) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO
        title="Application Management"
        description="Metsi expedites digital innovation initiatives by leveraging APIs for IT automation, orchestration and integration; optimising native cloud computing is used to reap the benefits of workflow automation."
        keywords={[
          "application management services",
          "application lifecycle management consulting",
          "devops application management",
          "application modernization services",
          "application modernisation ",
          "legacy application modernisation services",
          "legacy application modernisation",
        ]}
        lang="en-gb"
      />

      {/********* Hero Section ***********/}
      <BackgroundImage
        fluid={data.hero.childImageSharp.fluid}
        className="visibility-hero"
        style={{
          width: "100vw",

          backgroundPosition: "bottom",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div className="hero-content">
          <div className="transparent">
            <div className="outer-container">
              <div className="row">
                <div className="inner-container-2 left">
                  <div className="droplet-blue">
                    <BiSearchAlt2 className="icon" />
                  </div>
                </div>
                <div className="inner-container-2 right">
                  <div className="column">
                    <h1>Application Management</h1>
                    <img src={Divider} alt="divider" className="divider" />
                    <p style={{ color: "#626366" }}>
                      At its core, digital innovation is the use of digital
                      technology and applications to improve existing business
                      processes and efficiency, enhancing customer experience
                      and launching new products or business models effectively.
                      Digital innovation can include developing a new technology
                      strategy in an existing business; easily adopting new
                      software or platforms; or evolving from analogue to
                      digital operations.
                    </p>
                    {/* <ButtonB href="#services">Learn More</ButtonB> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </BackgroundImage>
      {/********* Services ***********/}
      <div className="section services" id="services">
        <div className="inner-container">
          <h2>Application Management</h2>
          <h3>Innovate with Full Stack Observability</h3>
          <p>
            Full-stack observability – or observability without limits - enables
            IT teams to monitor an entire IT stack, from customer-facing
            applications to core network and infrastructure, and determine which
            resources must take priority.
          </p>
          <p>
            Metsi expedites digital innovation initiatives by leveraging APIs
            for IT automation, orchestration and integration; optimising cloud
            native computing to reap the benefits of extensive workflow
            automation.
          </p>
        </div>
        <div className="row" style={{ maxWidth: "1400px" }}>
          <div className="inner-container-3">
            <a href="#observability">
              <div className="droplet-blue">
                <RiCompassDiscoverLine className="icon" />
              </div>
            </a>
            <a href="#observability">
              <h2 className="droplet-header" style={{ color: "#000" }}>
                Observability
              </h2>
            </a>
          </div>

          <div className="inner-container-3">
            <a href="#application-modernisation">
              <div className="droplet-blue">
                <IoIosAnalytics className="icon" />
              </div>
            </a>
            <a href="#application-modernisation">
              <h2 className="droplet-header" style={{ color: "#000" }}>
                Application Modernisation
              </h2>
            </a>
          </div>

          <div className="inner-container-3">
            <a href="#dev-ops">
              <div className="droplet-blue">
                <CgInsights className="icon" />
              </div>
            </a>
            <a href="dev-ops" style={{ color: "#000" }}>
              <h2 className="droplet-header">DevOps</h2>
            </a>
          </div>
        </div>
      </div>
      {/********* Observability ***********/}
      <BackgroundImage
        fluid={data.woods.childImageSharp.fluid}
        className="section"
        id="observability"
        style={{
          width: "100vw",
          minHeight: "60vh",
          backgroundPosition: "bottom",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: "#fff",
        }}
      >
        <div className="overlay section">
          <div className="outer-container">
            <div className="inner-container">
              <h2>Application Management</h2>
              <h3>Observability</h3>
            </div>
          </div>
          <TextAccordion panels={panels1} />
        </div>
      </BackgroundImage>

      {/********* App Modernisation ***********/}
      <div className="section" id="application-modernisation">
        <div className="overlay section">
          <div className="outer-container">
            <div className="inner-container">
              <h2>Application Management</h2>
              <h3>Application Modernisation</h3>
              <p>
                {" "}
                New At Metsi:{" "}
                <a
                  href="https://summit.metsi.co"
                  id="app-mod-link"
                  target="_blank"
                >
                  Take the Journey to the summit of Application Modernisation
                </a>
              </p>
            </div>
          </div>
          <TextAccordion panels={panels2} />
        </div>
      </div>

      {/******** DevOps ********/}
      <BackgroundImage
        fluid={data.forest.childImageSharp.fluid}
        className="section"
        id="dev-ops"
        style={{
          width: "100vw",
          minHeight: "60vh",
          backgroundPosition: "bottom",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: "#fff",
        }}
      >
        <div className="overlay section">
          <div className="outer-container">
            <div className="inner-container">
              <h2>Application Management</h2>
              <h3>DevOps</h3>
            </div>
          </div>
          <TextAccordion panels={panels3} />
        </div>
      </BackgroundImage>

      {/*********** Partners *************/}
      <div className="section partners">
        <div className="outer-container" style={{ display: "block" }}>
          <div
            className="droplet-blue-invert"
            style={{ marginTop: "25px", float: "left" }}
          >
            Our Partners
          </div>
          <div style={{ display: "block" }}>
            <LogoCarousel />
          </div>
        </div>
      </div>
      {/*********** Contact *************/}
      <div className="contact-section" id="contact">
        <div className="outer-container">
          <div className="contact-header">
            <h2>Contact Us</h2>
            <ScrollAnimation animateIn="animate__fadeInUp" duration="1.3">
              <h1 style={{ textAlign: "center" }}>
                Have a question about our solutions?
              </h1>
            </ScrollAnimation>
          </div>
          <div className="row">
            <div className="inner-container-2">
              <ContactForm />
            </div>
            <div className="inner-container-2">
              <div className="row">
                <div className="inner-container">
                  <img src={MapImg} alt="map" style={{ width: "100%" }} />
                </div>
              </div>
              <div className="row">
                <div
                  className="inner-sub-container-2"
                  style={{
                    alignItems: "flex-start",
                    textAlign: "left",
                    padding: "40px 50px",
                  }}
                >
                  <h4 style={{ color: "#21B7E0" }}>Office Locations</h4>
                  <ul>
                    <li>
                      <FaCircle className="icon" id="uk" />
                      UK
                    </li>
                    <li>
                      <FaCircle className="icon" id="usa" />
                      USA
                    </li>
                    <li>
                      <FaCircle className="icon" id="germany" />
                      Germany
                    </li>
                    <li>
                      <FaCircle className="icon" id="netherlands" />
                      The Netherlands
                    </li>
                    <li>
                      <FaCircle
                        className="icon"
                        id="india"
                        style={{ color: "transparent" }}
                      />
                    </li>
                  </ul>
                </div>
                <div
                  className="inner-sub-container-2"
                  style={{
                    alignItems: "flex-start",
                    textAlign: "left",
                    padding: "40px 50px",
                  }}
                >
                  <h4 style={{ color: "#21B7E0" }}>Satellite Locations</h4>
                  <ul>
                    <li>
                      <FaRegCircle className="icon" id="belgium" />
                      Belgium
                    </li>
                    <li>
                      <FaRegCircle className="icon" id="sa" />
                      South Africa
                    </li>
                    <li>
                      <FaRegCircle className="icon" id="poland" />
                      Poland
                    </li>
                    <li>
                      <FaRegCircle className="icon" id="spain" />
                      Spain
                    </li>
                    <li>
                      <FaRegCircle className="icon" id="india" />
                      India
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default VisibilityPage
